import React, { useContext, useState } from 'react';
import { FormContext } from '../../context/FormContext';

const Step1 = ({ nextStep }) => {
    const { formData, updateFormData } = useContext(FormContext);
    const [number, setNumber] = useState(formData.numberOforderLines);

    const handleNext = () => {
        updateFormData({ numberOforderLines: Number(number) });
        // Initialize orderLines array
        const orderLines = Array.from({ length: Number(number) }, () => ({
            taxId: '15.385.241/0001-73',
            name: 'Milênio 3',
            ShareholderCount: 4,
        }));
        updateFormData({ orderLines });
        nextStep();
    };

  return (
    <div>
      <h2 className="text-xl font-bold mb-4">Step 1: Number of orderLines</h2>
      <div className="mb-4">
        <label className="block mb-2">How many orderLines do you want to add in your order?</label>
        <input
          type="number"
          min="1"
          value={number}
          onChange={(e) => setNumber(e.target.value)}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2 outline-none"
        />
      </div>
      {number >= 2 && (
        <div className="bg-gray-100 p-3 rounded mb-4">
          All the {number} orderLines will be included in the same billing account. If you need separated billing accounts, please make different orders.
        </div>
      )}
      <button
        onClick={handleNext}
        className="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Next
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
        </svg>
      </button>
    </div>
  );
};

export default Step1;