import React, { useContext, useEffect, useState } from 'react';
import { FormContext } from '../../context/FormContext';
import { calculateTotalAmount } from '../../api/api';

const Step3 = ({ nextStep, prevStep }) => {
  const { formData, updateFormData } = useContext(FormContext);
  const [total, setTotal] = useState({ monthly: 0, annually: 0 });
  const [ loading, setLoading ] = useState(false);

  useEffect(() => {
    const fetchTotal = async () => {
      setLoading(true);
      const amount = await calculateTotalAmount(formData);
      setTotal(amount);
      setLoading(false);
    };
    fetchTotal();
  }, [formData]);

  const handleChange = (e) => {
    updateFormData({ billingFrequency: e.target.value });
  };

  const handleNext = () => {
    nextStep();
  };

  return (
    <div>
      <h2 className="text-xl font-bold mb-4">Step 3: Order Resume</h2>
      <div className="mb-4">
        <label className="block mb-2">Billing Frequency</label>
        <select
          value={formData.billingFrequency}
          onChange={handleChange}
          className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 outline-none"
        >
          <option value="Monthly">Monthly</option>
          <option value="Annually">Annually</option>
        </select>
      </div>
      <div className="mb-4">
        <p>Total Amount:</p>
        {
          loading && 
          <p>Calculating ...</p>
        }
        {
          !loading && formData.billingFrequency === "Monthly" && 
          <p>Monthly: R$ {total?.totalAmmount?.toFixed(2)}</p>
        }
        {
          !loading && formData.billingFrequency === "Annually" && 
          <p>Annually: R$ {total?.totalAmmount?.toFixed(2)}</p>
        }
      </div>
      <div className="flex justify-between">
        <button
          onClick={prevStep}
          className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 flex items-center gap-2"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 9-3 3m0 0 3 3m-3-3h7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
          Back
        </button>
        <button
          onClick={handleNext}
          className="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Next
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Step3;