import axios from "../setupAxios";

// Mock API functions

export const calculateTotalAmount = async (formData) => {


    const shareholdersCount = formData?.orderLines?.map(c => {
        return `shareholderCount=${c.ShareholderCount}`
    });
    try {
        const { data } = await axios.get(`/api/v1/order/OrderResume?${shareholdersCount.join("&")}&billingFrequency=${formData?.billingFrequency}&couponCode=${formData?.couponCode}`, 
            {
                withCredentials: false, 
                headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            }
        );
        // const res = await axios.get(`/api/v1/order/OrderResume?shareholderCount=4&shareholderCount=50&shareholderCount=11&billingFrequency=Annually&couponCode=MICRO202402`, 
        //     {
        //         withCredentials: false, 
        //         headers: {
        //             "Access-Control-Allow-Origin": "*"
        //         }
        //     }
        // );

        return data;
    } catch (error) {
        
    }

    // Simulate API delay
    await new Promise(resolve => setTimeout(resolve, 500));
  
    // Example calculation
    const baseAmount = formData.numberOforderLines * 100; // R$100 per company
    const monthly = baseAmount;
    const annually = baseAmount * 10; // 2 months free
  
    return { monthly, annually };
};
  
export const validateCoupon = async (code) => {

    try {
        const { data } = await axios.get(`/api/v1/order/ValidateCoupon/${code}`);
        return data;
    } catch (error) {
        return false;
    }
};
  
export const fetchBillingDetails = async (taxId) => {
    await new Promise(resolve => setTimeout(resolve, 500));
  
    // Mock billing details fetching
    if (taxId === '00.000.000/0000-00') {
        return {
            legalName: 'Empresa Exemplo Ltda',
            addressLine1: 'Rua Exemplo, 123',
            addressLine2: 'Bairro Exemplo',
            city: 'São Paulo',
            state: 'SP',
            postalCode: '01000-000',
            country: 'Brasil',
        };
    }
  
    return {};
};
  
export const submitOrder = async (formData) => {
    try {
        const { data } = await axios.post(`/api/v1/order/`, formData);

        return data;
    } catch (error) {
        console.log(error)
        return false;       
    }
};  