import React, { useContext, useEffect, useState } from 'react';
import { FormContext } from '../../context/FormContext';
import { validateCoupon } from '../../api/api';

const Step4 = ({ nextStep, prevStep }) => {
  const { formData, updateFormData } = useContext(FormContext);
  const [couponCode, setCouponCode] = useState(formData.couponCode || '');
  const [validation, setValidation] = useState(true);


  const handleNext = () => {
    setValidation(true);

    const fetchCoupon = async () => {
      const result = await validateCoupon(couponCode);
      if( result ) {
        updateFormData({ couponCode });
        nextStep();
      } else {
        setValidation(false)
      }
    };

    if (couponCode) {
      fetchCoupon();
    }
  };

  return (
    <div>
      <h2 className="text-xl font-bold mb-4">Step 4: Coupon</h2>
      <div className="mb-4">
        <label className="block mb-2">Coupon Code</label>
        <input
          type="text"
          value={couponCode}
          onChange={(e) => setCouponCode(e.target.value)}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2 outline-none"
        />
      </div>
      {/* {validation.discountPercentage > 0 || validation.trialDays > 0 ? (
        <div className="bg-green-100 p-3 rounded mb-4">
          {validation.discountPercentage > 0 && (
            <p>Discount: {validation.discountPercentage}%</p>
          )}
          {validation.trialDays > 0 && (
            <p>Trial Days: {validation.trialDays}</p>
          )}
          <p>New Total Amount: </p>
        </div>
      ) : (
        couponCode && (
          <div className="bg-red-100 p-3 rounded mb-4">
            Invalid Coupon Code.
          </div>
        )
      )} */}
      {
        !validation && 
        <div className="bg-red-100 p-3 rounded mb-4">
          Invalid Coupon Code.
        </div>
      }
      <div className="flex justify-between">
        <button
          onClick={prevStep}
          className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 flex items-center gap-2"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 9-3 3m0 0 3 3m-3-3h7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
          Back
        </button>
        <button
          onClick={handleNext}
          className="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Next
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Step4;