import React, { useContext, useState } from 'react';
import { FormContext } from '../../context/FormContext';

const Step7 = ({ nextStep, prevStep }) => {
  const { formData, updateFormData } = useContext(FormContext);
  const [billingContact, setBillingContact] = useState(formData.billingContact);

  const handleChange = (field, value) => {
    setBillingContact(prev => ({ ...prev, [field]: value }));
  };

  const handleCheckbox = () => {
    const sameAsAdmin = !billingContact.sameAsAdmin;
    setBillingContact(prev => ({
      sameAsAdmin,
      firstName: sameAsAdmin ? formData?.administrationContact?.firstName : '',
      lastName: sameAsAdmin ? formData?.administrationContact?.lastName : '',
      email: sameAsAdmin ? formData?.administrationContact?.email : '',
      businessPhone: sameAsAdmin ? formData?.administrationContact?.mobilePhone : '',
    }));
  };

  const handleNext = () => {
    updateFormData({ billingContact });
    nextStep();
  };

  return (
    <div>
      <h2 className="text-xl font-bold mb-4">Step 7: Billing Contact</h2>
      <div className="mb-4">
        <label className="inline-flex items-center">
          <input
            type="checkbox"
            checked={billingContact.sameAsAdmin}
            onChange={handleCheckbox}
            className="form-checkbox"
          />
          <span className="ml-2">Same as administration contact</span>
        </label>
      </div>
      {!billingContact.sameAsAdmin && (
        <>
          <div className="mb-4">
            <label className="block mb-1">First Name</label>
            <input
              type="text"
              value={billingContact.firstName}
              onChange={(e) => handleChange('firstName', e.target.value)}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2 outline-none"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-1">Last Name</label>
            <input
              type="text"
              value={billingContact.lastName}
              onChange={(e) => handleChange('lastName', e.target.value)}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2 outline-none"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-1">Email</label>
            <input
              type="email"
              value={billingContact.email}
              onChange={(e) => handleChange('email', e.target.value)}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2 outline-none"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-1">Mobile Phone (optional)</label>
            <input
              type="tel"
              value={billingContact.businessPhone}
              onChange={(e) => handleChange('businessPhone', e.target.value)}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2 outline-none"
            />
          </div>
        </>
      )}
      <div className="flex justify-between">
        <button
          onClick={prevStep}
          className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 flex items-center gap-2"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 9-3 3m0 0 3 3m-3-3h7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
          Back
        </button>
        <button
          onClick={handleNext}
          className="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Next
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Step7;