import axios from 'axios';

const axiosServices = axios.create({ baseURL: `https://subscription.scriptumdigital.com.br` });

// ==============================|| AXIOS - FOR MOCK SERVICES ||============================== //

axiosServices.interceptors.request.use(
    async (config) => {

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosServices.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject((error?.response && error?.response?.data) || 'Wrong Services');
  }
);

export default axiosServices;