import React, { useContext } from 'react';
import { FormContext } from '../../context/FormContext';

const Step2 = ({ nextStep, prevStep }) => {
    const { formData, updateFormData } = useContext(FormContext);
    const orderLines = formData.orderLines;

    const handleChange = (index, field, value) => {
        const updatedorderLines = orderLines.map((orderLine, i) => 
            i === index ? { ...orderLine, [field]: value } : orderLine
        );
        updateFormData({ orderLines: updatedorderLines });
    };

    const handleNext = () => {
        // Optional: Add validation here
        nextStep();
    };

  return (
    <div>
      <h2 className="text-xl font-bold mb-4">Step 2: Company Details</h2>
      {orderLines.map((company, index) => (
        <div key={index} className="mb-6 p-4 border rounded">
          <h3 className="text-lg font-semibold mb-2">Company {index + 1}</h3>
          <div className="mb-4">
            <label className="block mb-1">Tax ID (CNPJ)</label>
            <input
              type="text"
              value={company.taxId}
              onChange={(e) => handleChange(index, 'taxId', e.target.value)}
              pattern="\d{2}\.?\d{3}\.?\d{3}/?\d{4}-?\d{2}"
              placeholder="00.000.000/0000-00"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2 outline-none"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-1">Company Name</label>
            <input
              type="text"
              value={company.name}
              onChange={(e) => handleChange(index, 'name', e.target.value)}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2 outline-none"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-1">Shareholder Count</label>
            <input
              type="number"
              min="1"
              value={company.ShareholderCount}
              onChange={(e) => handleChange(index, 'ShareholderCount', e.target.value)}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2 outline-none"
            />
          </div>
        </div>
      ))}
      <div className="flex justify-between">
        <button
          onClick={prevStep}
          className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 flex items-center gap-2"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 9-3 3m0 0 3 3m-3-3h7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>

          Back
        </button>
        <button
          onClick={handleNext}
          className="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Next
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Step2;