import React, { useContext, useState } from 'react';
import { FormContext } from '../../context/FormContext';
import { fetchBillingDetails } from '../../api/api';

const Step5 = ({ nextStep, prevStep }) => {
  const { formData, updateFormData } = useContext(FormContext);
  const [billingDetails, setBillingDetails] = useState(formData.billingAddress);
  const [billingTaxId, setBillingTaxId] = useState(formData.billingTaxId);
  const [loading, setLoading] = useState(false);

  const handleChange = (field, value) => {
    setBillingDetails(prev => ({ ...prev, [field]: value }));
  };

  const handleFetchDetails = async () => {
      setLoading(true);
      const details = await fetchBillingDetails(billingTaxId);
      setBillingDetails(prev => ({ ...prev, ...details }));
      updateFormData({ billingDetails: { ...details }, billingTaxId });
      setLoading(false);
  };

  const handleNext = () => {
    updateFormData({ billingDetails });
    nextStep();
  };

  return (
    <div>
      <h2 className="text-xl font-bold mb-4">Step 5: Billing Details</h2>
      <div className="mb-4">
        <label className="block mb-1">Billing Tax ID (CNPJ)</label>
        <div className="flex">
          <input
            type="text"
            value={billingTaxId}
            onChange={(e) => setBillingTaxId(e.target.value)}
            pattern="\d{2}\.?\d{3}\.?\d{3}/?\d{4}-?\d{2}"
            placeholder="00.000.000/0000-00"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2 outline-none"
          />
          <button
            onClick={handleFetchDetails}
            className="ml-2 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
            disabled={loading}
          >
            {loading ? 'Fetching...' : 'Fetch'}
          </button>
        </div>
      </div>
      <div className="mb-4">
        <label className="block mb-1">Company Legal Name</label>
        <input
          type="text"
          value={billingDetails?.legalName}
          onChange={(e) => handleChange('legalName', e.target.value)}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2 outline-none"
        />
      </div>
      <div className="mb-4">
        <label className="block mb-1">Address Line 1 (Logradouro)</label>
        <input
          type="text"
          value={billingDetails?.addressLine1}
          onChange={(e) => handleChange('addressLine1', e.target.value)}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2 outline-none"
        />
      </div>
      <div className="mb-4">
        <label className="block mb-1">Address Line 2 (Bairro)</label>
        <input
          type="text"
          value={billingDetails?.addressLine2}
          onChange={(e) => handleChange('addressLine2', e.target.value)}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2 outline-none"
        />
      </div>
      <div className="mb-4">
        <label className="block mb-1">City (Localidade)</label>
        <input
          type="text"
          value={billingDetails?.city}
          onChange={(e) => handleChange('city', e.target.value)}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2 outline-none"
        />
      </div>
      <div className="mb-4">
        <label className="block mb-1">State (Estado)</label>
        <input
          type="text"
          value={billingDetails?.state}
          onChange={(e) => handleChange('state', e.target.value)}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2 outline-none"
        />
      </div>
      <div className="mb-4">
        <label className="block mb-1">Postal Code (CEP)</label>
        <input
          type="text"
          value={billingDetails?.postalCode}
          onChange={(e) => handleChange('postalCode', e.target.value)}
          placeholder="00000-000"
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2 outline-none"
        />
      </div>
      <div className="mb-4">
        <label className="block mb-1">Country (País)</label>
        <input
          type="text"
          value={billingDetails?.country}
          onChange={(e) => handleChange('country', e.target.value)}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2 outline-none"
        />
      </div>
      <div className="flex justify-between">
        <button
          onClick={prevStep}
          className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 flex items-center gap-2"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 9-3 3m0 0 3 3m-3-3h7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
          Back
        </button>
        <button
          onClick={handleNext}
          className="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Next
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Step5;